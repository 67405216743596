<template>
  <div>
    <video src="https://example-study1.oss-cn-beijing.aliyuncs.com/video/yuwen2.mp4" controls></video>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>